import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccount } from 'hooks/authService'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Switch,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import Card from 'components/card/Card'
import { Icon } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY'
// import { getUserAccount } from 'hooks/authService'

export default function AnimalsForm(props) {
  const { client_id, account_id, animal_id } = props
  const textColor = useColorModeValue('navy.700', 'white')

  const [formData, setFormData] = useState({
    account_id: account_id,
    owner_id: parseInt(client_id, 10),
    name: '',
    birthdate: '',
    breed: '',
    fur: '',
    gender: '',
    age: '',
    tags: [],
    sterilization: '',
    species: '',
    status: '',
    animal_data: {
      weight: ''
    }
  })

  useEffect(() => {
    getAnimalData()
  }, [])

  async function getAnimalData() {
    if (!animal_id) return

    try {
      const response = await axios.get(`/animals/${animal_id}`, { headers: authHeader() })
      setFormData(response.data)
      setSelectedSpecies(response.data.species)
      // console.log('AnimalData: ', response.data)
    } catch (error) {
      console.log('Erro ao carregar os dados do animal.', error)
    }
  }

  async function deleteAnimal() {
    if (!animal_id) return
    try {
      const response = await axios.delete(`/animals/${animal_id}`, { headers: authHeader() })
      setFormData(response.data)
      // console.log('AnimalData: ', response.data)
    } catch (error) {
      console.log('Erro ao carregar os dados do animal.', error)
    }
  }

  function formatarData(data) {
    const dataObj = new Date(data)
    const ano = dataObj.getFullYear()
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0')
    const dia = String(dataObj.getDate()).padStart(2, '0')
    return `${ano}-${mes}-${dia}`
  }

  const [sendAlert, setSendAlert] = useState('')

  useEffect(() => {
    if (sendAlert === 'success') {
      setTimeout(() => {
        setSendAlert('')
      }, 3000)
    }
  }, [sendAlert])

  useEffect(() => {
    // console.log('formData animals: ', formData)
  }, [formData])

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleChangeWeight = (event) => {
    const { value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      animal_data: {
        ...prevFormData.animal_data,
        weight: value
      }
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (animal_id) {
      try {
        const response = await axios.patch(`/animals/${animal_id}`, formData, {
          headers: authHeader()
        })
        console.log('Animal atualizado com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o animal:', error.response.data)
        setSendAlert('error')
      }
    } else {
      try {
        const response = await axios.post('/animals', formData, {
          headers: authHeader()
        })
        console.log('Animal cadastrado com sucesso!', response.data)
        setSendAlert('success')
        setTimeout(() => {
          window.location.href = `/admin/client/${client_id}` // Corrigido: usar response.data.id
        }, 1000)
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o animal:', error.response.data)
        setSendAlert('error')
      }
    }

    console.log(formData)
  }

  const speciesOptions = ['Canino', 'Felino', 'Ave', 'Bovino', 'Coelho', 'Equino', 'Roedor', 'Suínos']

  const breedOptions = {
    Canino: [
      'Affenpinscher',
      'Afghanhound',
      'Airedale Terrier',
      'Akita',
      'American bully',
      'American Staffordshire Terrier',
      'Australian Silky Terrier',
      'Basenji',
      'Bassethound',
      'Beagle',
      'Bearded Collie',
      'Beauceron',
      'Bedlington',
      'Bergamasco',
      'Bichon Frise',
      'Black And Tan Coonhound',
      'Bloodhound',
      'Bobtail',
      'Border Collie',
      'Border Terrier',
      'Borzoi',
      'Boston Terrier',
      'Bouvier de Flandres',
      'Boxer',
      'Braco Frances',
      'Braco Italiano',
      'Briard',
      'Buldogue francês',
      'Buldogue Ingles',
      'Bull Terrier',
      'Bull Terrier Miniatura',
      'Bulldogue',
      'Bullmastiff',
      'Cairn Terrier',
      'Cavalier King',
      'Chesapeake',
      'Chihuahua',
      'Chinese Crested Dog',
      'Chow Chow',
      'Clumber Spaniel',
      'Cocker Spaniel Americano',
      'Cocker Spaniel Ingles',
      'Collie',
      'Dachshund',
      'Dachshund Miniatura',
      'Dalmata',
      'Deerhound',
      'Dobermann',
      'Dogo Argentino',
      'Dogue Alemão',
      'Elkhound Noruegues',
      'Fila Brasileiro',
      'Flat Coated Retriever',
      'Fox Terrier',
      'Fox Terrier Brasileiro',
      'Foxhound Americano',
      'Foxhound Ingles',
      'Galgo Afegão',
      'Golden Retriever',
      'Greyhound',
      'Husky Siberiano',
      'Irish Terrier',
      'Jack Russell',
      'Jagdterrier',
      'Keeshond',
      'Komondor',
      'Leonberger',
      'Lhasa Apso',
      'Lulu da Pomerânia',
      'Malamute dos Alasca',
      'Maltês',
      'Mastiff',
      'Mastim dos Pirineus',
      'Mastim Napolitano',
      'Norwich Terrier',
      'Old English Sheepdog',
      'Papillon',
      'Pastor Alemão',
      'Pastor Belga',
      'Pastor de Shetland',
      'Pequinês',
      'Pinscher',
      'Pinscher Miniatura',
      'Pit Bull',
      'Pointer Alemão',
      'Pointer Ingles',
      'Poodle Gigante',
      'Poodle Micro',
      'Poodle Standard',
      'Poodle Toy',
      'Pug',
      'Puli',
      'Retriever dos Labrador',
      'Rhodesian Ridgeback',
      'Rottweiller',
      'Sabujo',
      'Saluki',
      'Samoieda',
      'São Bernardo',
      'Schipperkee',
      'Schnauzer',
      'Schnauzer Anão',
      'Schnauzer Gigante',
      'Scotch Terrier',
      'Scottish Terrier',
      'Setter Gordon',
      'Setter Ingles',
      'Setter Irlandes',
      'Shar-pei',
      'Shiba inu',
      'Shih- Tzu',
      'Silk Terrier',
      'Skye Terrier',
      'Slughi',
      'Spaniel Bretão',
      'Spitz',
      'Springer Spaniel',
      'SRD',
      'Staffordshire Bull Terrier',
      'Terra Nova',
      'Vizsla',
      'Weimaraner',
      'Welsh Corgi',
      'West highland white terrier',
      'Whippet',
      'Wolfhound Irlandes',
      'Yorkshire Terrier'
    ],
    Felino: [
      'Angora',
      'Balinês',
      'Blue Point',
      'Bobtail Japonês',
      'Chartreux',
      'Cornish rex',
      'Devon Rex',
      'Habana',
      'Himalaio',
      'Javanês',
      'Maine Coon',
      'Manx',
      'Norueguês da Floresta',
      'Persa',
      'Pelo Curto Brasileiro',
      'Ragdoll',
      'Sagrado da Birmânia',
      'Siamês',
      'Somali',
      'Tenerife'
    ],
    Ave: [
      'Calopsita',
      'Periquito Australiano',
      'Canário',
      'Papagaio',
      'Cacatua',
      'Agapornis',
      'Mainá',
      'Diamante Gould',
      'Mandarim',
      'Jandaia',
      'Maritaca',
      'Ring-neck',
      'Caturra',
      'Arara',
      'Curió',
      'Coleiro',
      'Pássaro Preto',
      'Pintassilgo',
      'Rosela'
    ],
    Bovino: [
      'Angus',
      'Brahman',
      'Brangus',
      'Charolês',
      'Gir',
      'Guzerá',
      'Holandês',
      'Jersey',
      'Nelore',
      'Simental',
      'Tabapuã',
      'Girolando',
      'Hereford',
      'Limousin',
      'Pardo-Suíço',
      'Devon',
      'Red Angus',
      'Bonsmara',
      'Santa Gertrudis',
      'Shorthorn'
    ],
    Equino: [
      'Árabe',
      'Puro Sangue Inglês',
      'Quarto de Milha',
      'Mangalarga Marchador',
      'Appaloosa',
      'Paint Horse',
      'Lusitano',
      'Frísio',
      'Hanoveriano',
      'Andaluz',
      'Mustang',
      'Shetland',
      'Percheron',
      'Clydesdale',
      'Shire',
      'Crioulo',
      'Tennessee Walker',
      'Morgan',
      'Falabella',
      'Trotador Standardbred',
      'Peruano de Paso',
      'Hackney',
      'Marwari',
      'Knabstrupper',
      'Paso Fino',
      'Lipizzaner',
      'Fjord',
      'Rocky Mountain Horse',
      'Trotador Francês',
      'Welsh Pony',
      'Dartmoor Pony',
      'Exmoor Pony',
      'Connemara Pony',
      'New Forest Pony',
      'Dales Pony',
      'Highland Pony',
      'Eriskay Pony',
      'Garrano',
      'Pony Brasileiro',
      'Pony Shetland Miniatura',
      'American Saddlebred',
      'Cavalo Basco',
      'Akhal-Teke',
      'Bashkir Curly',
      'Pony de Polo',
      'Gidran',
      'Cavalo Menorquino',
      'Camargue Horse',
      'Cavalo Brasileiro de Hipismo',
      'Cavalo Brasileiro de Marcha',
      'Cavalo Marajoara',
      'Pony Crioulo',
      'Mustang Brasileiro'
    ],
    Roedor: [
      'Hamster - Sírio',
      'Hamster - Russo',
      'Hamster - Chinês',
      'Porquinho-da-índia - Cabelo curto',
      'Porquinho-da-índia - Cabelo longo (Peruano ou Sheltie)',
      'Porquinho-da-índia - Cabelo crespo (Abissínio)',
      'Porquinho-da-índia - Cabelo espiralado (Coronet)',
      'Gerbil - Mongólia',
      'Chinchila - Pelo curto',
      'Chinchila - Pelo longo'
    ],
    Coelho: [
      'Angorá',
      'Belier',
      'Californiano',
      'Chinchila',
      'Flandres Gigante',
      'Holandês',
      'Lion Head',
      'Mini Rex',
      'Netherland Dwarf',
      'Rex',
      'Polonês',
      'Rhinelander',
      'Tan',
      'Himalaio',
      'Havana',
      'Himalaio',
      'Lop'
    ],
    Suinos: [
      'Large White',
      'Duroc',
      'Landrace',
      'Hampshire',
      'Pietrain',
      'Berkshire',
      'Yorkshire',
      'Poland China',
      'Chester White'
    ]
  }

  const [selectedSpecies, setSelectedSpecies] = useState('')
  const [selectedBreed, setSelectedBreed] = useState('')

  const handleSpeciesChange = (e) => {
    setSelectedSpecies(e.target.value)
    setSelectedBreed('')
    setFormData((prevFormData) => ({
      ...prevFormData,
      species: e.target.value
    }))
  }

  const handleBreedChange = (e) => {
    setSelectedBreed(e.target.value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      breed: e.target.value
    }))
  }

  return (
    <Card>
      <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
        Adicionar animal
      </Text>
      <Card>
        <SimpleGrid columns={{ base: '1', sm: '2', md: '3' }} gap="0px 20px">
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Nome do animal
            </FormLabel>
            <Input
              value={formData.name}
              name="name"
              onChange={handleChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Nome do animal"
              mb="24px"
              fontWeight="500"
              size="md"
            />
          </Flex>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Sexo
            </FormLabel>
            <Select
              value={formData.gender}
              name="gender"
              onChange={handleChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Selecione"
              mb="24px"
              fontWeight="500"
              size="md"
            >
              <option key="female" value="female">
                Fêmea
              </option>
              <option key="male" value="male">
                Macho
              </option>
              <option key="unknow" value="unknow">
                Não identificado
              </option>
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Esterilização
            </FormLabel>
            <Select
              value={formData.sterilization}
              name="sterilization"
              onChange={handleChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Selecione"
              mb="24px"
              fontWeight="500"
              size="md"
            >
              <option key="Castrado" value="Castrado">
                Castrado
              </option>
              <option key="Vasectomizado" value="Vasectomizado">
                Vasectomizado
              </option>
              <option key="Fértil" value="Fértil">
                Fértil
              </option>
            </Select>
          </Flex>
          <FormControl id="species">
            <FormLabel>Espécie</FormLabel>
            <Select
              placeholder="Selecione a espécie"
              value={formData.species}
              onChange={handleSpeciesChange}
              name="species"
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              mb="24px"
              fontWeight="500"
            >
              {speciesOptions.map((species) => (
                <option key={species} value={species}>
                  {species}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl id="breed" isDisabled={!formData.species}>
            <FormLabel>Raça</FormLabel>
            <Select
              placeholder="Selecione a raça"
              value={formData.breed}
              onChange={handleBreedChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              mb="24px"
              fontWeight="500"
              size="md"
            >
              {selectedSpecies &&
                breedOptions[selectedSpecies].map((breed) => (
                  <option key={breed} value={breed}>
                    {breed}
                  </option>
                ))}
            </Select>
          </FormControl>
          {/* <Flex direction="column">
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Espécie
              </FormLabel>

              <Select
                value={formData.species}
                name="species"
                onChange={handleChange}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Selecione"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                {[
                  'Avícola',
                  'Bovinos',
                  'Canina',
                  'Cunícula',
                  'Equina',
                  'Exótico',
                  'Felina',
                  'Outras',
                  'Primatas',
                  'Roedor'
                ].map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Flex> */}
          {/* <Flex direction="column">
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Raça
              </FormLabel>
              <Select
                value={formData.breed}
                name="breed"
                onChange={handleChange}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Selecione a Raça"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                {[
                  'Affenpinscher',
                  'Afghanhound',
                  'Airedale Terrier',
                  'Akita',
                  'American bully',
                  'American Staffordshire Terrier',
                  'Angora',
                  'Australian Silky Terrier',
                  'Balinês',
                  'Basenji',
                  'Bassethound',
                  'Beagle',
                  'Bearded Collie',
                  'Beauceron',
                  'Bedlington',
                  'Bergamasco',
                  'Bichon Frise',
                  'Black And Tan Coonhound',
                  'Bloodhound',
                  'Blue Point',
                  'Bobtail',
                  'Bobtail Japonês',
                  'Border Collie',
                  'Border Terrier',
                  'Borzoi',
                  'Boston Terrier',
                  'Bouvier de Flandres',
                  'Boxer',
                  'Braco Frances',
                  'Braco Italiano',
                  'Briard',
                  'Buldogue francês',
                  'Buldogue Ingles',
                  'Bull Terrier',
                  'Bull Terrier Miniatura',
                  'Bulldogue',
                  'Bullmastiff',
                  'Cairn Terrier',
                  'Calopsita',
                  'Cavalier King',
                  'Chartreux',
                  'Chesapeake',
                  'Chihuahua',
                  'Chinese Crested Dog',
                  'Chow Chow',
                  'Clumber Spaniel',
                  'Cocker Spaniel Americano',
                  'Cocker Spaniel Ingles',
                  'Collie',
                  'Cornish rex',
                  'Dachshund',
                  'Dachshund Miniatura',
                  'Dalmata',
                  'Deerhound',
                  'Devon Rex',
                  'Dobermann',
                  'Dogo Argentino',
                  'Dogue Alemão',
                  'Elkhound Noruegues',
                  'Fila Brasileiro',
                  'Flat Coated Retriever',
                  'Fox Terrier',
                  'Fox Terrier Brasileiro',
                  'Foxhound Americano',
                  'Foxhound Ingles',
                  'Galgo Afegão',
                  'Golden Retriever',
                  'Greyhound',
                  'Habana',
                  'Hamster',
                  'Himalaio',
                  'Husky Siberiano',
                  'Irish Terrier',
                  'Jack Russell',
                  'Jagdterrier',
                  'Javanês',
                  'Keeshond',
                  'Komondor',
                  'Leonberger',
                  'Lhasa Apso',
                  'Lulu da Pomerânia',
                  'Maine Coon',
                  'Malamute dos Alasca',
                  'Maltês',
                  'Manx',
                  'Mastiff',
                  'Mastim dos Pirineus',
                  'Mastim Napolitano',
                  'Norueguês da Floresta',
                  'Norwich Terrier',
                  'Old English Sheepdog',
                  'Papillon',
                  'Pastor Alemão',
                  'Pastor Belga',
                  'Pastor de Shetland',
                  'Pelo Curto Brasileiro',
                  'Pequinês',
                  'Persa',
                  'Pinscher',
                  'Pinscher Miniatura',
                  'Pit Bull',
                  'Pointer Alemão',
                  'Pointer Ingles',
                  'Poodle Gigante',
                  'Poodle Micro',
                  'Poodle Standard',
                  'Poodle Toy',
                  'Pug',
                  'Puli',
                  'Ragdoll',
                  'Retriever dos Labrador',
                  'Rhodesian Ridgeback',
                  'Rottweiller',
                  'Sabujo',
                  'Sagrado da Birmânia',
                  'Saluki',
                  'Samoieda',
                  'São Bernardo',
                  'Schipperkee',
                  'Schnauzer',
                  'Schnauzer Anão',
                  'Schnauzer Gigante',
                  'Scotch Terrier',
                  'Scottish Terrier',
                  'Setter Gordon',
                  'Setter Ingles',
                  'Setter Irlandes',
                  'Shar-pei',
                  'Shiba inu',
                  'Shih- Tzu',
                  'Siamês',
                  'Silk Terrier',
                  'Skye Terrier',
                  'Slughi',
                  'Somali',
                  'Spaniel Bretão',
                  'Spitz',
                  'Springer Spaniel',
                  'SRD',
                  'Staffordshire Bull Terrier',
                  'Tenerife',
                  'Terra Nova',
                  'Vizsla',
                  'Weimaraner',
                  'Welsh Corgi',
                  'West highland white terrier',
                  'Whippet',
                  'Wolfhound Irlandes',
                  'Yorkshire Terrier'
                ].map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Flex> */}
          <FormControl id="fur" isDisabled={!formData.breed}>
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Pelagem
            </FormLabel>
            <Select
              isDisabled={!formData.breed}
              value={formData.fur}
              name="fur"
              onChange={handleChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Selecione a espécie"
              mb="24px"
              fontWeight="500"
              size="md"
            >
              {[
                'Abricot',
                'Amarelo',
                'Arlequim',
                'Azul Ruão',
                'Bege',
                'Black And Tan',
                'Boston',
                'Branca',
                'Branco e Bege',
                'Branco e Cinza',
                'Branco e Marrom',
                'Branco e Preto',
                'Branco e Vermelho',
                'Bronze',
                'Carvao',
                'Champanhe',
                'Cinza',
                'Dourado',
                'Fulvo',
                'Indefinida',
                'Manto Negro',
                'Marrom',
                'Pelo Duro (Arame)',
                'Pintado',
                'Preta',
                'Preto e Amarelo',
                'Preto e Marrom',
                'Preto e Vermelho',
                'Preto, branco e Marrom',
                'Sal e Pimenta',
                'Tigrado',
                'Tricolor',
                'Vermelho',
                'Vermelho e Branco'
              ].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
          </FormControl>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Data Nascimento
            </FormLabel>
            <Input
              value={formatarData(formData.birthdate)}
              name="birthdate"
              onChange={handleChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Data Nascimento"
              mb="24px"
              fontWeight="500"
              size="md"
              type="Date"
            />
          </Flex>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Peso (Kgs)
            </FormLabel>
            <Input
              value={formData.animal_data.weight}
              name="weight"
              onChange={handleChangeWeight}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Peso em Kgs"
              mb="24px"
              fontWeight="500"
              size="md"
            />
          </Flex>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Status
            </FormLabel>
            <Select
              value={formData.status}
              name="status"
              onChange={handleChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Selecione"
              mb="24px"
              fontWeight="500"
              size="md"
            >
              <option key="Vivo" value="Vivo">
                Vivo
              </option>
              <option key="Morto" value="Morto">
                Morto
              </option>
            </Select>
          </Flex>
        </SimpleGrid>
        <Flex>
          <Button
            onClick={(event) => handleSubmit(event)}
            borderRadius="50px"
            width={'fit-content'}
            style={{
              // background: 'rgb(61, 219, 255)',
              background: '#49BFFF',
              fontWeight: '800',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {!animal_id && <Icon as={MdAdd} width="20px" height="20px" color="inherit" textAlign="center" />}
            {animal_id ? 'Salvar' : 'Adicionar animal'}
          </Button>

          {animal_id && (
            <Button
              colorScheme="red"
              onClick={() => deleteAnimal()}
              borderRadius="50px"
              width={'fit-content'}
              ml="10px"
            >
              Excluir
            </Button>
          )}
        </Flex>
      </Card>
      {sendAlert === 'success' && (
        <Alert status="success">
          <AlertIcon />
          <AlertTitle>Sucesso!</AlertTitle>
          <AlertDescription>Dados enviados com sucesso!</AlertDescription>
        </Alert>
      )}
    </Card>
  )
}

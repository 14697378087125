import React, { useState, useEffect, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Icon,
  Link,
  Textarea,
  SimpleGrid,
  Switch,
  Select,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import AddTermToInput from 'components/addTermToInput'

export default function ScriptForm(props) {
  const { campaign_id, campaignName } = props
  const textColor = useColorModeValue('navy.700', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')

  const account_id = getUserAccountId()

  const { script_id: scriptIdFromParams } = useParams() || ''
  const [script_id, setClientId] = useState(scriptIdFromParams)

  const textareaRef = useRef(null)
  const [textAreaValue, setTextAreaValue] = useState('')

  const [accountName, setAccountName] = useState('s')
  const [formData, setFormData] = useState({
    subject: campaignName,
    message: '',
    campaign_id: campaign_id,
    account_id: account_id,
    step: ''
  })

  const [scriptList, setScriptList] = useState([])
  const [refresh, setRefresh] = useState('')

  useEffect(() => {
    // console.log('Script formData:', formData)
  }, [account_id, script_id, formData, scriptIdFromParams])

  useEffect(() => {
    getCampaignScripts()
  }, [refresh])

  async function getCampaignScripts() {
    if (!campaign_id) return
    try {
      const { data } = await axios.get(
        `/scripts?account_id=${account_id}&campaign_id=${campaign_id}&$sort[id]=1`,
        {
          headers: authHeader()
        }
      )
      setScriptList(data.data)
      // console.log('Scripts data', data)
    } catch (error) {
      console.log('Erro ao carregar dados do cliente: ', error)
    }
  }

  useEffect(() => {
    async function getAccountName() {
      try {
        const { data } = await axios.get(`/accounts/${account_id}`, { headers: authHeader() })
        console.log('Conta:', data.name)
        setAccountName(data.name)
      } catch (err) {
        console.log('Erro: ', err.response.data)
      }
    }

    if (account_id) {
      getAccountName()
    }
  }, [])

  const [sendAlert, setSendAlert] = useState('')

  useEffect(() => {
    if (sendAlert === 'success') {
      setTimeout(() => {
        setSendAlert('')
      }, 3000)
    }
  }, [sendAlert])

  const handleChange = (event) => {
    const { name, value } = event.target
    setTextAreaValue(event.target.value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))

    if (name === 'step') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subject: scriptsContext[value - 1].subject
      }))
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (script_id) {
      try {
        const response = await axios.patch(`/scripts/${script_id}`, formData, {
          headers: authHeader()
        })
        setRefresh(response.data)
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/scripts', formData, {
          headers: authHeader()
        })
        console.log('Script cadastrado com sucesso!', response.data)
        setRefresh(response.data)
        setClientId(response.data.id)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  const scriptsContext = [
    { subject: 'Abordagem inicial', step: 1 },
    { subject: '2ª Tentativa', step: 2 },
    { subject: '3ª Tentativa', step: 3 },
    { subject: 'Encerramento', step: 4 }
  ]

  return (
    <Flex flexDirection="column" gap="24px">
      {scriptList &&
        scriptList.map((script) => (
          <Card key={script.id}>
            <Flex flexDirection="column">
              <Text fontWeight={600} mb="12px" display="flex" justifyContent="space-between">
                {`${script.subject}`}
                <Link href={`/admin/scripts/${script.id}`}>
                  <Icon
                    as={MdEdit}
                    color={iconColor}
                    w="24px"
                    h="24px"
                    style={{ background: '#fafafa', padding: '5px', borderRadius: '10px' }}
                  />
                  {/* <Text fontSize="sm" fontWeight="500">
                    Editar
                  </Text> */}
                </Link>
              </Text>
              <Text fontSize="sm">{script.message}</Text>
            </Flex>
          </Card>
        ))}
      <Card>
        <Text fontWeight={600} mb="12px">
          Nova mensagem
        </Text>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <SimpleGrid rows={2} gap="0px 20px">
              <Flex direction="column">
                <Select
                  value={formData.step}
                  name="step"
                  isRequired={true}
                  onChange={handleChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Contexto da abordagem"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                  defaultValue={'Primeira abordagem'}
                >
                  {scriptsContext.map((script) => (
                    <option value={script.step} key={script.step}>
                      {script.subject}
                    </option>
                  ))}
                </Select>
              </Flex>
            </SimpleGrid>

            <FormControl display="flex" flexDirection={'column'}>
              <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
                Menssagem
              </FormLabel>
              <Textarea
                name="message"
                value={textAreaValue}
                onChange={handleChange}
                isRequired={true}
                variant="filled"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Olá {{TutorNome}}, tudo bem?"
                fontWeight="500"
                size="md"
                ref={textareaRef}
              />
              <FormHelperText mb="24px">
                <AddTermToInput
                  textToCopy="{{TutorNome}}"
                  textareaRef={textareaRef}
                  setTextAreaValue={setTextAreaValue}
                  mr="5px"
                />
                <AddTermToInput
                  textToCopy="{{AnimalNome}}"
                  textareaRef={textareaRef}
                  setTextAreaValue={setTextAreaValue}
                />
                <AddTermToInput
                  textToCopy="{{AnimalIdade}}"
                  textareaRef={textareaRef}
                  setTextAreaValue={setTextAreaValue}
                />
                <AddTermToInput
                  textToCopy="{{o/a}}"
                  textareaRef={textareaRef}
                  setTextAreaValue={setTextAreaValue}
                />
                <AddTermToInput
                  textToCopy="{{ele/ela}}"
                  textareaRef={textareaRef}
                  setTextAreaValue={setTextAreaValue}
                />
                <AddTermToInput
                  textToCopy="{{NomeEmpresa}}"
                  textareaRef={textareaRef}
                  setTextAreaValue={setTextAreaValue}
                />
                <Text fontSize={'sm'} mt="-10px" pl="10px">
                  Clique nas opções acima para adicionar os termos na sua mensagem.
                </Text>
              </FormHelperText>
            </FormControl>

            <Button
              type="submit"
              fontSize="sm"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="100%"
              h="50"
              mb="24px"
              borderRadius="50px"
            >
              Salvar
            </Button>
            {sendAlert === 'success' && (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Sucesso!</AlertTitle>
                <AlertDescription>Dados enviados com sucesso!</AlertDescription>
              </Alert>
            )}
          </FormControl>
        </form>
      </Card>
    </Flex>
  )
}

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'

import { Box, useColorModeValue, Text, Flex, SimpleGrid } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { Icon } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import ClientAnimalsCards from './clientAnimalsCards'
import MessageComposer from './MessageComposer'

export default function ClientAnimals() {
  const textColor = useColorModeValue('navy.700', 'white')

  const { client_id: clientIdFromParams } = useParams() || ''
  const [client_id, setClientId] = useState(clientIdFromParams)
  const [animalsList, setAnimalsList] = useState([])

  console.log('clientId:', client_id)

  useEffect(() => {
    async function getClientAnimals() {
      try {
        const { data } = await axios.get(`/animals?owner_id=${client_id}`, { headers: authHeader() })
        // console.log('data Animas: ', data.data)
        setAnimalsList(data.data)
      } catch (error) {
        console.log('Erro: ', error)
      }
    }

    getClientAnimals()
  }, [])

  return (
    <Box>
      {client_id && (
        <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px" mt="24px">
          Animais do tutor
        </Text>
      )}

      <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} gap="20px">
        {animalsList &&
          animalsList.map((animal, index) => <ClientAnimalsCards key={index} animal={animal} />)}
      </SimpleGrid>
    </Box>
  )
}

import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  Switch,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderTrack,
  SliderThumb,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import formatDate from 'hooks/formatDate'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import ApproachForm from '../../newAproach/components/newApproachForm'
import NewCampaignScript from './campaignScript'
import { getUserAccountId } from 'hooks/authService'
import IntervalInput from 'components/intervalInput'

export default function CampaignForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { campaignId: campaignIdFromParams } = useParams() || ''
  const [campaign_id, setCampaignId] = useState(parseInt(campaignIdFromParams, 10))

  const [formData, setFormData] = useState({
    subject: '',
    description: '',
    account_id: account_id,
    cadence_id: 1,
    priority: 5,
    tags: {
      Atendimento: [],
      Especies: [],
      Idade: [],
      'Status do Cliente': []
    },
    status: 'active',
    intervals_msec: 15780096000,
    start_date: '',
    end_date: ''
  })

  function getToday() {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1 // Months are 0-indexed
    let day = today.getDate()

    // Add leading zeros if necessary
    if (month < 10) month = `0${month}`
    if (day < 10) day = `0${day}`

    return { today: `${year}-${month}-${day}`, years: `${year + 2}-${month}-${day}` } // Format for input type="date"
  }

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      start_date: getToday().today,
      end_date: getToday().years
    }))
  }, [])

  const [interval, setInterval] = useState(15780096000.000004)

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      intervals_msec: parseInt(interval, 10)
    }))
  }, [interval])

  useEffect(() => {
    // console.log('campaign formData: ', formData)
  }, [account_id, formData, campaignIdFromParams])

  const [sendAlert, setSendAlert] = useState('')

  useEffect(() => {
    if (sendAlert === 'success') {
      setTimeout(() => {
        setSendAlert('')
      }, 3000)
    }
  }, [sendAlert])

  useEffect(() => {
    getCampaign()
  }, [])

  async function getCampaign() {
    if (!campaign_id) return
    try {
      const response = await axios.get(`/campaigns/${campaign_id}`, {
        headers: authHeader()
      })

      response.data.intervals_msec = parseInt(response.data.intervals_msec, 10)

      setFormData(response.data)
    } catch (error) {
      console.log('Erro: ', error.response.data)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSlideChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      priority: value
    }))
  }

  const handleCheckboxCategoryChange = (event, groupName) => {
    const value = event.target.value
    setFormData((prevFormData) => {
      const newCategories = { ...prevFormData.tags }

      if (!Array.isArray(newCategories[groupName])) {
        newCategories[groupName] = []
      }

      if (event.target.checked) {
        newCategories[groupName] = [...newCategories[groupName], value]
      } else {
        newCategories[groupName] = newCategories[groupName].filter((item) => item !== value)
      }

      return {
        ...prevFormData,
        tags: newCategories
      }
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (campaign_id) {
      try {
        const response = await axios.patch(`/campaigns/${campaign_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/campaigns', formData, {
          headers: authHeader()
        })
        console.log('Campanha cadastrada com sucesso!', response.data)
        setCampaignId(response.data.id)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  const checkboxGroups = {
    Atendimento: ['Preventivo', 'Campanha'],
    Especies: ['Canino', 'Felino'],
    Idade: ['Filhote 1 ano', 'Filhote 2 anos', 'Adulto 2 a 6 anos', 'Idoso 6+ anos'],
    'Status do Cliente': ['Ativo', 'Estagnado', 'Inativo']
  }

  const CheckboxGrid = () => {
    return (
      <SimpleGrid columns={2} gap={5}>
        {Object.entries(checkboxGroups).map(([groupName, values]) => (
          <Flex
            key={groupName}
            style={{ marginBottom: '16px' }}
            direction="column"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
          >
            <strong>{groupName}</strong>
            <Flex direction="column">
              {values.map((value) => (
                <Checkbox
                  key={value}
                  value={value}
                  isChecked={formData.tags[groupName]?.includes(value)}
                  onChange={(e) => handleCheckboxCategoryChange(e, groupName)}
                  style={{ marginLeft: '8px' }}
                  fontSize="sm"
                  fontWeight="400"
                  color={textColor}
                >
                  {value}
                </Checkbox>
              ))}
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    )
  }

  return (
    <>
      <SimpleGrid columns={2} gap={5}>
        <Card>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
                Nova Campanha
              </Text>
              <Flex direction="column">
                {/* <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Tema
              </FormLabel> */}
                <Input
                  value={formData.subject}
                  name="subject"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                {/* <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Descrição
              </FormLabel> */}
                <Textarea
                  value={formData.description}
                  name="description"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Descrição da campanha"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                  border="1px solid #E2E8F0"
                  borderRadius="16px"
                />
              </Flex>
              <SimpleGrid columns={{ base: '1', md: '2' }} gap="0px 20px">
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Data de Início
                  </FormLabel>
                  <Input
                    value={formatDate(formData.start_date)}
                    name="start_date"
                    onChange={handleChange}
                    isRequired={true}
                    variant="auth"
                    type="date"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Nome"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Data de Finalização
                  </FormLabel>
                  <Input
                    value={formatDate(formData.end_date)}
                    name="end_date"
                    onChange={handleChange}
                    isRequired={true}
                    variant="auth"
                    type="date"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Nome"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
                <Flex flexDirection="row" justifyContent="center" alignItems="flex-start" mb="24px">
                  <FormLabel htmlFor="status" variant="auth" fontWeight="500" fontSize="sm" mb="8px">
                    Status Ativo?
                  </FormLabel>
                  <Switch
                    id="status"
                    checked={formData.status === 'active'} // Determina o estado inicial do switch
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        status: event.target.checked ? 'active' : 'inactive' // Inverte o status ao alternar
                      }))
                    }
                    defaultChecked
                  />
                </Flex>
                <Flex flexDirection="column">
                  <FormLabel htmlFor="status" variant="auth" fontWeight="500" fontSize="sm">
                    Prioridade da campanha
                  </FormLabel>
                  <Slider
                    name="priority"
                    focusThumbOnChange={false}
                    value={formData.priority}
                    onChange={handleSlideChange}
                    defaultValue={5}
                    min={1}
                    max={10}
                  >
                    <SliderMark value={2} fontSize="sm" mt="8px">
                      Baixa
                    </SliderMark>
                    <SliderMark value={8} fontSize="sm" mt="8px">
                      Alta
                    </SliderMark>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb fontSize="sm" boxSize="32px" children={formData.priority} />
                  </Slider>
                </Flex>
                <Flex flexDirection="column">
                  <FormLabel htmlFor="status" variant="auth" fontWeight="500" fontSize="sm">
                    Intervalo entre repetições
                  </FormLabel>
                  <IntervalInput onChange={setInterval} value={parseInt(formData.intervals_msec, 10)} />
                </Flex>
              </SimpleGrid>

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="md"
                fontWeight="800"
                color={textColor}
                mt="24px"
                mb="8px"
              >
                Contexto da Campanha
              </FormLabel>
              <CheckboxGrid />

              <Button
                type="submit"
                fontSize="sm"
                background="#49BFFF"
                color="white"
                fontWeight="800"
                w="100%"
                h="50"
                mb="24px"
                borderRadius="50px"
              >
                Salvar
              </Button>
              {sendAlert === 'success' && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Sucesso!</AlertTitle>
                  <AlertDescription>Dados enviados com sucesso!</AlertDescription>
                </Alert>
              )}
            </FormControl>
          </form>
        </Card>
        {campaign_id && <NewCampaignScript campaign_id={campaign_id} campaignName={formData.subject} />}
      </SimpleGrid>
    </>
  )
}

// id: Type.Number(),
//     account_id: Type.Number(),
//     name: Type.String(),
//     message: Type.String(),
//     step: Type.String(), // aproach, response 1, response 2, ?
//     category: Type.String(), // theme or subject
//     status: Type.String(), // active, suspended
//     createdAt: Type.Optional(Type.String({ format: 'date-time' })),
//     updatedAt: Type.Optional(Type.String({ format: 'date-time' }))

import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Switch,
  Select,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'

export default function ApproachQueueForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { script_id: scriptIdFromParams } = useParams() || ''
  const [script_id, setScript_id] = useState(scriptIdFromParams)

  // table.integer('account_id')
  // table.integer('owner_id'
  // table.integer('animal_id')
  // table.integer('script_id')
  // table.integer('cadence_id')
  // table.enum('media', ['email', 'whatsapp']).notNullable()
  // table.text('message').notNullable()
  // table.enum('status', ['sent', 'received', 'read', 'responded', 'scheduled', 'invoiced']).notNullable()
  // table.timestamps(true, true)

  const [formData, setFormData] = useState({
    account_id: account_id,
    owner_id: null,
    animal_id: null,
    cadence_id: null,
    script_id: null,
    message: '',
    media: 'whatsapp', // 'whatsapp'/'email'
    status: 'notsent' //'sent', 'received', 'read', 'responded', 'scheduled', 'invoiced'
  })

  const [accountScriptList, setAccountScriptlist] = useState([])
  const [whatsappNumbersList, setWhatsappNumbersList] = useState({
    numbers: []
  })

  useEffect(() => {
    console.log('Approach formData:', formData)
    console.log('whatsappNumbersList: ', whatsappNumbersList.numbers)
  }, [account_id, script_id, formData, scriptIdFromParams, whatsappNumbersList])

  useEffect(() => {
    async function getAccountScripts() {
      try {
        const { data } = await axios.get(`/scripts?account_id=${account_id}`, { headers: authHeader() })
        setAccountScriptlist(data.data)
      } catch (error) {
        console.log('Erro ao carregar scripts: ', error)
      }
    }

    if (account_id) {
      getAccountScripts()
    }
  }, [])

  const [sendAlert, setSendAlert] = useState('')

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'script_id') {
      const scriptId = +value
      const scriptselectedis = accountScriptList.find((script) => script.id === scriptId)
      console.log('scriptselectedis: ', scriptselectedis)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: scriptId,
        message: scriptselectedis.message
      }))
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }))
    }
  }

  const handleChangeWhatsappNumbersList = (event) => {
    const { value } = event.target
    const numbersArray = value.split(',').map((number) => number.trim())

    setWhatsappNumbersList({ numbers: numbersArray })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    await processWhatsappNumbers(whatsappNumbersList)

    console.log('handleSubmit enviado.')

    // try {
    //   const response = await axios.post('/approachs', formData, {
    //     headers: authHeader()
    //   })
    //   console.log('Abordagem registrada com sucesso!', response.data)
    //   setClientId(response.data.id)
    //   setSendAlert('success')
    // } catch (error) {
    //   console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
    //   setSendAlert('error')
    // }
    console.log(formData)
  }

  const getRandomDelay = () => {
    return Math.floor(Math.random() * (50 - 10 + 1) + 10) * 1000
  }

  const sendPostRequest = async (number) => {
    const wapiUrl = 'https://wapi.conta.com.vc/message/sendText/1151283600'

    console.log('number do sendPostRequest: ', number)

    const whastappMessageToken = {
      number,
      options: {
        delay: 0,
        presence: 'composing',
        linkPreview: false
      },
      textMessage: {
        text: formData.message
      }
    }
    console.log('whastappMessageToken: ', whastappMessageToken)

    try {
      const response = await fetch(wapiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          apikey: 'wyjrdjr2zqj4fdw5fi53nu'
        },
        body: JSON.stringify(whastappMessageToken)
      })
      console.log('response do fetch: ', response)
      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`)
      }

      const data = await response.json()
      console.log(`Successfully sent to ${number}:`, data)
    } catch (error) {
      console.error(`Error sending to ${number}:`, error)
    }
  }

  const processWhatsappNumbers = async ({ numbers }) => {
    console.log('processWhatsappNumbers Função: ', numbers)

    for (let i = 0; i < numbers.length; i++) {
      console.log('numbers[i]: ', numbers[i])

      await sendPostRequest(numbers[i])

      if (i < numbers.length - 1) {
        const randomDelay = getRandomDelay()

        // Assegura que o delay é de pelo menos 14 segundos
        const delay = Math.max(randomDelay, 14000)

        console.log(`Waiting for ${delay / 1000} seconds before sending to the next number...`)

        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
              Configuração da abordagem
            </Text>
            <SimpleGrid columns={{ base: '1', md: '2' }} gap="0px 20px">
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Assunto
                </FormLabel>
                <Input
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Script
                </FormLabel>
                <Select
                  value={formData.script_id}
                  name="script_id"
                  onChange={handleChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Selecione"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                >
                  {accountScriptList.map((value) => {
                    if (value.step === 'Primeira abordagem')
                      return (
                        <option value={value.id} key={value.id}>
                          {value.subject}
                        </option>
                      )
                  })}
                </Select>
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Canal de comunicação
                </FormLabel>
                <Select
                  value={formData.media}
                  name="media"
                  onChange={handleChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Selecione"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                >
                  {['Whatsapp', 'Email', 'Direct', 'Linkedin', 'SMS'].map((value) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </Flex>
            </SimpleGrid>
            <FormControl display="flex" flexDirection={'column'}>
              <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
                Lista de números de WhatsApp
              </FormLabel>
              <Textarea
                name="numbers"
                value={whatsappNumbersList.numbers.join(', ')}
                onChange={handleChangeWhatsappNumbersList}
                isRequired={true}
                variant="filled"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Os números devem estar separados por vírgula e possuir o código do pais."
                fontWeight="500"
                size="md"
              />
              <FormHelperText mb="24px">{`Exemplo: 551151283600`}</FormHelperText>
            </FormControl>
            <FormControl display="flex" flexDirection={'column'}>
              <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
                Menssagem
              </FormLabel>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                isRequired={true}
                variant="filled"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Olá {{TutorNome}}, tudo bem?"
                fontWeight="500"
                size="md"
              />
              <FormHelperText mb="24px">{`Variáveis: {{TutorNome}}  {{AnimalNome}}  {{AnimalIdade}}`}</FormHelperText>
            </FormControl>

            <Button
              type="submit"
              fontSize="sm"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="100%"
              h="50"
              mb="24px"
              borderRadius="50px"
            >
              Enviar
            </Button>
            {sendAlert === 'success' && (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Sucesso!</AlertTitle>
                <AlertDescription>Dados enviados com sucesso!</AlertDescription>
              </Alert>
            )}
          </FormControl>
        </form>
      </Card>
    </>
  )
}
